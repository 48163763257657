<template>
  <div v-if="isLoading == true" class="d-flex justify-content-center">
    <b-spinner class="text-center" type="grow" variant="primary" animated small></b-spinner>
  </div>
  <div v-else>
    <div class="col-12">
      <div class="form-group">
        <label for="input-1">Start Date:</label>
        <b-form-select v-model="selected" :options="stickerOptions"></b-form-select>
      </div>
    </div>
    <div class="col-12">
      <b-button @click="updateStickerType" variant="primary">
        <div v-if="isLoading">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <div v-else>Submit</div>
      </b-button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { campaigns } from "../../../config/api/campaigns";
export default {
  data() {
    return {

      selected: null,
      stickerOptions: [
        {
          text: "Large",
          value: "Large"
        },
        {
          text: "Medium",
          value: "Medium"
        }
        , {
          text: "Small",
          value: "Small"
        }
      ],
      isLoading: false,
    };
  },
  props: {
    userId: {
      type: String,
    },

    vehicleId: {
      type: String,
    },
    sticker_type: {
      type: String,
    },
  },
  mounted() {

    this.selected = this.sticker_type || "Medium"
  },

  methods: {
    updateStickerType() {
      this.isLoading = true;
      const api = campaigns.updateStickerType;
      //TODO: add conditions for currentAd and upcomingAd

      api.data = {
        vehicle_id: this.vehicleId,
        sticker_type: this.selected
      };
      console.log(api.data);
      this.generateAPI(api)
        .then((resdata) => {
          console.log(resdata);

          this.isLoading = false;
          this.$emit("closeModal");
        })
        .catch((e) => {
          console.log(e.response);
          Swal.fire("Error", e.response.data.error, "warning");
          this.$emit("closeModal");
        });
    },
  },
};
</script>

<style>
</style>
