<template>
  <div>
    <b-form>
      <div class="row">
        <div class="col-sm-12">
          <FormulateInput name="serial_no" v-model="serialNo" type="text" label="Terminal Serial Number"
            placeholder="SN Number" validation="required" />
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="!search">
          <span class="badge badge-soft-success" v-if="found">An existing terminal found with this
            {{this.serialNo}}</span>
          <span class="badge badge-soft-danger" v-else>No terminals found with this {{this.serialNo}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <b-button @click="checkTerminalExist" variant="primary" class="mt-2">
            <div v-if="isLoading">
              <b-spinner small type="grow"></b-spinner>
            </div>
            <div v-else>Search</div>
          </b-button>
        </div>
        <div class="col-3">
          <b-button @click="updateInfo" variant="primary" class="mt-2">
            <div v-if="isLoading">
              <b-spinner small type="grow"></b-spinner>
            </div>
            <div v-else>Submit</div>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { driver } from "../../../config/api/drivers";
import { terminals } from "@/config/api/terminals";

export default {
  props: {
    user_id: {
      type: String
    },
    serial_no: {
      type: String
    },
  },

  mounted() {
    this.serialNo = this.serial_no
    this.form = {
      driver_id: this.user_id
    }
  },
  watch: {
    serialNo() {
      this.search = true;
    }
  },
  methods: {

    async checkTerminalExist() {
      let api = terminals.search;
      if (this.serialNo) {
        api.params = {
          serial_no: this.serialNo
        }
        let respose = await this.generateAPI(api);
        this.search = false;
        this.found = !!respose.data.tms
      }
    },
    updateInfo() {
      this.isLoading = true;
      const api = driver.terminal_info;
      api.data = { ...this.form, serial_no: this.serialNo };
      this.generateAPI(api).then((resData) => {
        console.log(resData);
        this.isLoading = false;
        this.$emit("closeModal");
      }).catch((e) => {
        Swal.fire("Error", e.response.data.message, "warning");

      });
    },
  },
  data() {
    return {
      isLoading: false,
      search: true,
      found: true,
      serialNo: '',
      form: {},
    };
  },
};
</script>

<style>

</style>
