<template>
  <div>
    <FilePond name="file" ref="pond" label-idle="Upload File" accepted-file-types="image/jpeg, image/png"
      v-bind:server="server" allowRemove="false" allow-multiple="false" v-on:init="handleFilePondInit"
      v-on:updatefiles="onFileUploaded" />
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { API_ENDPOINT } from "../../config/axios";
const FilePond = vueFilePond();
export default {
  components: {
    FilePond,
  },
  created() {
    this.getToken();
  },
  methods: {
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async getToken() { },
    onFileUploaded(data) {
      if (data.location != null) this.$emit("file-uploaded", data.location);
    },
  },
  data() {
    return {
      token: "",

      server: {
        process: {
          url: API_ENDPOINT + "file/upload",
          method: "POST",
          withCredentials: false,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("Access-Token")),
          },
          timeout: 7000,
          //   onload: (response) => this.updateFile(JSON.parse(response)),
          onerror: null,
          onload: (response) => this.onFileUploaded(JSON.parse(response)),
        },
      },
    };
  },
};
</script>

<style>
</style>
