<template>
    <div>
        <b-form>
            <div class="row">
                <div class="col-9">
                    <FormulateInput v-model="selectedCity" :options="citys" type="select" label="City"
                        name="selectedCity" placeholder="City" />
                </div>



            </div>
            <b-button @click="updateInfo" variant="primary" class="mt-2">
                <div v-if="isLoading">
                    <b-spinner small type="grow"></b-spinner>
                </div>
                <div v-else>Submit</div>
            </b-button>
        </b-form>
    </div>
</template>


<script>
import Swal from "sweetalert2";
import { driver } from "../../../config/api/drivers";

export default {
    props: ["vehicle_id"],
    data() {
        return {
            isLoading: false,
            citys: ['Riyad', 'Jeddah'],
            selectedCity: "Riyad",
        };
    },
    methods: {

        updateInfo() {
            this.isLoading = true;
            const api = driver.changeLocation;
            api.data = { city: this.selectedCity, vehicle_id: this.vehicle_id }
            this.generateAPI(api).then((resData) => {
                console.log(resData);
                this.isLoading = false;
                this.$emit("closeModal");
            }).catch((e) => {
                Swal.fire("Error", e.response.data.message, "warning");
                this.$emit("closeModal");
            });
        },
    },

};
</script>

<style>

</style>
